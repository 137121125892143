import { useSelector } from "react-redux";
import "./index.scss";

const WheelHistory = ({ onHide }) => {
  const history = useSelector((state) => state.history.list);

  return (
    <div className="modal-overlap">
      <div className="modal">
        <div className="close-btn-77" onClick={onHide}></div>
        <div className="header">
          <h4>Reward History</h4>
        </div>

        <div className="icons">
          {history.map(({ winPot }, idx) => (
            <div className="icon" key={idx}>
              <img
                src={
                  winPot === "A"
                    ? "/img/lichi.png"
                    : winPot === "B"
                    ? "/img/77.png"
                    : "/img/wattermilon.png"
                }
                className="img"
              />
            </div>
          ))}
        </div>

        <div className="footer">
          <div className="item">
            <img src="/img/lichi.png" className="img" />
            {Math.round(
              (100 * history.filter((i) => i.winPot === "A").length) /
                history.length
            )}
            %
          </div>
          <div className="item">
            <img src="/img/77.png" className="img" />
            {Math.round(
              (100 * history.filter((i) => i.winPot === "B").length) /
                history.length
            )}
            %
          </div>
          <div className="item">
            <img src="/img/wattermilon.png" className="img" />
            {Math.round(
              (100 * history.filter((i) => i.winPot === "C").length) /
                history.length
            )}
            %
          </div>
        </div>
      </div>
    </div>
  );
};

export default WheelHistory;
