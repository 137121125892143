import { configureStore, combineReducers } from "@reduxjs/toolkit";

import history from "./slice/history";

const rootReducer = combineReducers({
  history,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export default store;
