import React, { useEffect, useState } from "react";

import "./index.scss";

const Wheel = (props) => {
  const [selectedItem, setSelectedItem] = useState(null);


  useEffect(() => {
    setSelectedItem(props.winIndex);
  }, [props.winIndex]);

  return (
    <div className="wheel-container">
      <div
        className={`wheel ${selectedItem !== null ? "spinning" : ""}`}
        style={{
          "--nb-item": props.items.length,
          "--selected-item": selectedItem,
        }}
        // onClick={selectItem}
      >
        {props.items.map((item, index) => (
          <div
            className="wheel-item"
            key={index}
            style={{ "--item-nb": index }}
          >
          </div>
        ))}
      </div>
    </div>
  );
};

export default Wheel;
