import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    addData: (state, { payload }) => {
      state.list = payload;
    },
    pushNewData: (state, { payload }) => {
      state.list.unshift(payload);
        state.list.splice(-1);
    },
  },
});

export const { addData, pushNewData } = historySlice.actions;

export default historySlice.reducer;
