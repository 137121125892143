import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import Timer from "../components/timer/Timer";

import Game from "../components/wheel";
import WheelHistory from "../components/wheel-history/WheelHistory";
import { addData, pushNewData } from "../store/slice/history";

const socket = io("https://game-api.heartlive.net/luck-77", {
  auth: {
    UID: new URLSearchParams(window.location.search).get("uid"),
  },
});

const Luck77 = () => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);
  const [selectedCoin, setSelectedCoin] = useState(0);
  const [time, setTime] = useState(0);
  const [coin, setCoin] = useState(0);
  const [showHistory, setShowHistory] = useState(false);

  const [winIndex, setWinIndex] = useState(null);
  const [winBoard, setWinBoard] = useState(null);

  const [bac, setBAC] = useState(0);
  const [bbc, setBBC] = useState(0);
  const [bcc, setBCC] = useState(0);

  const [ypa, setYPA] = useState(0);
  const [ypb, setYPB] = useState(0);
  const [ypc, setYPC] = useState(0);

  const [alert, setAlert] = useState("Connecting with server...");

  const loadHistory = () => {
    fetch(
      `https://game-api.heartlive.net/game-history/luck77?uid=${new URLSearchParams(
        window.location.search
      ).get("uid")}`
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data?.list) {
          dispatch(addData(data.list));
        }
      });
  };

  // Fake Bid
  // useEffect(() => {
  //   if (time > 5) {
  //     if (Math.random() <= 0.3) return;

  //     let coins = [100, 1000, 10000, 100000];

  //     const randomBoard = Math.round(Math.random() * 3);

  //     if (randomBoard === 0) {
  //       setBAC((prev) => prev + coins[Math.floor(Math.random() * coins.length)]);
  //     } else if (randomBoard === 1) {
  //       setBBC((prev) => prev + coins[Math.floor(Math.random() * coins.length)]);
  //     } else {
  //       setBCC((prev) => prev + coins[Math.floor(Math.random() * coins.length)]);
  //     }
  //   }
  // }, [time]);

  useEffect(() => {
    loadHistory();
  }, []);

  const numberFormatter = (num) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  };

  useEffect(() => {
    if (alert && !isLoading) {
      setTimeout(() => setAlert(false), 2000);
    }
  }, [alert, isLoading]);

  useEffect(() => {
    socket.on("connect", () => {
      setLoading(false);

      console.log("Server Connected.");
    });

    socket.on("reconnect", () => {
      setLoading(false);
      setAlert("Reconnect successful.");
    });

    socket.on("connect_error", (err) => {
      setLoading(true);
      setTime(0);

      if (err?.data?.authError) {
        setAlert(err.message);
      } else {
        setAlert("Connection lost. retrying...");
      }
    });

    socket.on("TIK", (time) => {
      setTime(time);
    });

    socket.on("ON_GET_RESULT", (winIndex, win) => {
      setWinIndex(winIndex);

      setTimeout(() => dispatch(pushNewData({ winPot: win })), 4000);

      setTimeout(() => {
        setWinBoard(win);

        setYPA((prev) => {
          if (win === "A" && Boolean(prev)) {
            let winRate = prev * 2;
            setAlert(
              `Congratulations! You Win ${numberFormatter(winRate)} beans.`
            );
          }

          return prev;
        });

        setYPB((prev) => {
          if (win === "B" && Boolean(prev)) {
            let winRate = prev * 8;
            setAlert(
              `Congratulations! You Win ${numberFormatter(winRate)} beans.`
            );
          }

          return prev;
        });

        setYPC((prev) => {
          if (win === "C" && Boolean(prev)) {
            let winRate = prev * 2;
            setAlert(
              `Congratulations! You Win ${numberFormatter(winRate)} beans.`
            );
          }

          return prev;
        });
      }, 6000);

      setTimeout(() => {
        setYPA(0);
        setYPB(0);
        setYPC(0);

        setWinBoard(null);
        setWinIndex(null);
      }, 10000);
    });

    socket.on("BALANCE_CHANGED", (balance, a, b, c) => {
      setCoin(parseInt(balance));

      if (a) setBAC(a);
      if (b) setBBC(b);
      if (c) setBCC(c);
    });

    socket.on("BOARD_A_COIN_CHANGED", (coin) => setBAC(coin));
    socket.on("BOARD_B_COIN_CHANGED", (coin) => setBBC(coin));
    socket.on("BOARD_C_COIN_CHANGED", (coin) => setBCC(coin));
  }, []);

  useEffect(() => {
    if (winBoard === "A") {
      if (!Boolean(ypa) && Boolean(ypb + ypc)) {
        setAlert("Bad luck! Try again.");
      }
    } else if (winBoard === "B") {
      if (!Boolean(ypb) && Boolean(ypa + ypc)) {
        setAlert("Bad luck! Try again.");
      }
    } else {
      if (!Boolean(ypc) && Boolean(ypa + ypb)) {
        setAlert("Bad luck! Try again.");
      }
    }
  }, [winBoard]);

  const handleBetCoinSelect = (amount) => {
    if (amount <= coin) {
      setSelectedCoin(amount === selectedCoin ? 0 : amount);
    } else {
      setAlert("Insufficient coins.");
    }
  };

  const handleBoardSelect = (board) => {
    if (selectedCoin === 0) {
      setAlert("Please select a coin first.");
      return;
    }

    if (!Boolean(time)) {
      setAlert("Please wait for new game...");
      return;
    }

    if (coin - selectedCoin < selectedCoin) {
      setSelectedCoin(0);
    }

    // send coin to server
    socket.emit("BET", selectedCoin, board);

    switch (board) {
      case "A":
        setYPA(ypa + selectedCoin);
        break;
      case "B":
        setYPB(ypb + selectedCoin);
        break;

      default:
        setYPC(ypc + selectedCoin);
        break;
    }
  };

  return (
    <>
      {Boolean(alert) && <div className="alert">{alert}</div>}

      <div className="background-image"></div>

      {showHistory && <WheelHistory onHide={() => setShowHistory(false)} />}

      <div
        className="close-btn-77"
        onClick={() => {
          window.ReactNativeWebView.postMessage("CLOSE_GAME");
        }}
      ></div>

      <Timer time={time} topClass="clock-luck77" />

      <Game time={time} winIndex={winIndex} items={Array(9).fill()} />

      <div className="bet-cards">
        <div
          className={`mcard pot_a  ${winBoard === "A" ? "win-animation" : ""}`}
          onClick={() => handleBoardSelect("A")}
        >
          {/* <div className="header">{numberFormatter(bac)}</div>

          <div className="body">
            <img src="/img/kirschen.png" />

            <h3>2x</h3>
          </div>
*/}
          <div className="footer">
            {Boolean(bac) ? numberFormatter(bac) : " "}
          </div>
        </div>

        <div
          className={`mcard pot_b  ${winBoard === "B" ? "win-animation" : ""}`}
          onClick={() => handleBoardSelect("B")}
        >
          {/* <div className="header">{numberFormatter(bbc)}</div>

          <div className="body">
            <img src="/img/77.png" />
            <h3>8x</h3>
          </div> */}

          <div className="footer">
            {Boolean(bbc) ? numberFormatter(bbc) : " "}
          </div>
        </div>

        <div
          className={`mcard pot_c  ${winBoard === "C" ? "win-animation" : ""}`}
          onClick={() => handleBoardSelect("C")}
        >
          {/* <div className="header">{numberFormatter(bcc)}</div>

          <div className="body">
            <img src="/img/apple.png" />
            <h3>2x</h3>
          </div> */}

          <div className="footer">
            {Boolean(bcc) ? numberFormatter(bcc) : " "}
          </div>
        </div>
      </div>

      <div className="action bottom-0">
        <div className="left">
          <img className="hart" src="/img/hart.png" alt="" />
          <span className="balance">{numberFormatter(coin)}</span>{" "}
          <a
            href="#"
            onClick={() =>
              window.ReactNativeWebView.postMessage("OPEN_TOP_UP_SCREEN")
            }
            style={{
              color: "gold",
            }}
          >
            Top Up>
          </a>
        </div>

        <div className="right">
          <div
            className={`${selectedCoin === 100 ? "coin active" : "coin"} ${
              coin < 100 && "disable"
            } `}
            onClick={() => handleBetCoinSelect(100)}
          >
            100
          </div>
          <div
            className={`${selectedCoin === 1000 ? "coin active" : "coin"} ${
              coin < 1000 && "disable"
            }`}
            onClick={() => handleBetCoinSelect(1000)}
          >
            1000
          </div>
          <div
            className={`${selectedCoin === 10000 ? "coin active" : "coin"} ${
              coin < 10000 && "disable"
            } `}
            onClick={() => handleBetCoinSelect(10000)}
          >
            10k
          </div>
          <div
            className={`${selectedCoin === 100000 ? "coin active" : "coin"} ${
              coin < 100000 && "disable"
            }`}
            onClick={() => handleBetCoinSelect(100000)}
          >
            100k
          </div>

          <a
            href="#"
            className="history"
            onClick={() => setShowHistory(true)}
          ></a>
        </div>
      </div>
    </>
  );
};

export default Luck77;
